import { createRouter, createWebHistory } from "vue-router";

import main from "./pages/mainPage.vue";

const routes = [
	{
		path: "/:department?",
		component: main,
		name: "Main",
		pageTitle: "Main",
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
