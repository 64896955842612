<template>
	<el-carousel ref="carousel" arrow="always" style="height: 100%" direction="vertical" :autoplay="false">
		<el-carousel-item :style="{ backgroundColor: image.color }" v-for="(image, i) in images" :key="i">
			<img style="width: 100%; margin-top: 20%" :src="image.img" />
		</el-carousel-item>
	</el-carousel>
</template>

<script>
import img1 from "../assets/1.png";
import img2 from "../assets/2.png";
import img3 from "../assets/3.png";
import img4 from "../assets/4.png";
import img5 from "../assets/5.png";
export default {
	data() {
		return {
			images: [
				{ img: img1, color: "#008751" },
				{ img: img2, color: "white" },
				{ img: img3, color: "white" },
				{ img: img4, color: "white" },
				{ img: img5, color: "white" },
			],
		};
	},
	methods: {
		toImage(imageNumber) {
			this.$refs.carousel.setActiveItem(imageNumber - 1);
		},
	},
};
</script>

<style>
.el-carousel__item h3 {
	color: #475669;
	opacity: 0.75;
	line-height: 200px;
	margin: 0;
	text-align: center;
}

.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
	background-color: #d3dce6;
}

.el-carousel__container {
	height: 100%;
}

.el-carousel__indicator--vertical {
	background: black;
}
</style>
