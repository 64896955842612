<template>
	<div class="common-layout">
		<el-container style="height: 100%">
			<!-- <el-main v-if="!isMobile"><useCaseTable ref="table" /></el-main> -->
			<el-main style="margin: 0px; padding: 0px" v-if="!isMobile">
				<imageCarousel ref="imageCarousel" />
			</el-main>
			<el-aside class="aside" :width="isMobile ? '100%' : '40%'"
				><chatConsole @toPage="toPage" @surveyComplete="surveyComplete"
			/></el-aside>
		</el-container>
	</div>
</template>

<script>
import chatConsole from "@/components/chatConsole.vue";
//import useCaseTable from "@/components/useCaseTable.vue";
//import pdfViewer from "@/components/pdfViewer.vue";
import imageCarousel from "@/components/imageCarousel.vue";
export default {
	components: {
		chatConsole,
		//useCaseTable,
		//pdfViewer,
		imageCarousel,
	},
	data() {
		return {
			isMobile: false,
		};
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.handleResize);
	},
	mounted() {
		this.handleResize();
		window.addEventListener("resize", this.handleResize);
	},
	methods: {
		toPage(pageNumber) {
			this.$refs.imageCarousel.toImage(pageNumber);
		},
		handleResize() {
			if (document.documentElement.clientWidth < 1080) {
				this.isMobile = true;
			} else if (document.documentElement.clientWidth > 1080) {
				this.isMobile = false;
			}
		},
		surveyComplete() {
			if (this.$refs.table) {
				this.$refs.table.refreshTable();
			}
		},
	},
};
</script>

<style scoped>
.common-layout {
	height: 100%;
}

.aside {
	border-left: 1px solid #e0e1e3;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>
