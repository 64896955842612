<template>
	<div class="chatConsole">
		<div class="header">
			<img class="widgetIcon" :src="bankLogo" />

			<el-button
				:loading="loading"
				@click="resetConversation"
				style="margin-left: auto; margin-right: 20px"
				size="small"
				type="primary"
				bg
				text
			>
				Reset survey
			</el-button>
		</div>
		<div
			style="
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				font-size: 10px;
				border-bottom: 1px solid #e0e1e3;
			"
		>
			Powered By
			<a style="color: #5f63df" href="https://tovie.ai/generative-ai-for-enterprise"
				><img style="height: 10px; margin-left: 5px" :src="tovieLogo"
			/></a>
		</div>
		<el-alert
			style="margin-bottom: 20px"
			title="Warnung"
			type="warning"
			description="Bitte geben Sie in der Umfrage keine sensiblen persönlichen oder geschäftlichen Informationen ein."
			show-icon
		/>
		<LazyList
			v-if="chatHistory.length"
			defaultLoadingColor="var(--el-color-primary)"
			:data="chatHistory"
			:itemsPerRender="10000"
			v-loading="loadingTranscript"
			ref="consoleBody"
			containerClasses="consoleBody"
		>
			<template v-slot="{ item }">
				<div :key="item.chatId" :class="(item.actor === 'user' ? 'user' : 'agent') + ' chatItem fade-in'">
					<div class="avatarWrapper">
						<el-avatar
							:style="{ backgroundColor: item.actor === 'user' ? '#FA5687' : 'var(--el-color-primary)' }"
						>
							<b v-if="item.actor === 'user'" style="font-size: 16px">U</b>
							<img
								style="margin: 0px"
								v-if="item.actor !== 'user'"
								class="widgetIcon"
								:src="widgetIcon"
							/>
						</el-avatar>
					</div>
					<div
						:style="{
							width: !item.text ? '100%' : '',
							border:
								readOnly && item.feedback
									? item.feedback?.options.find((each) => {
											return each.actioned;
									  }).text === 'No'
										? '2px solid red'
										: '2px solid rgb(58, 127, 58)'
									: '',
						}"
						class="content"
					>
						<p>
							<thinkingAnimation v-if="!item.text" />
							<Markdown
								:highlight="{ auto: true, hljs: require('highlight.js'), code: true }"
								:source="item.text"
							/>
						</p>
						<div
							class="sources"
							v-if="item.responseOptions && item.responseOptions.length && !item.responseOptionActioned"
						>
							<span>Antwortmöglichkeiten:</span>
							<div class="fade-in">
								<el-button
									class="source"
									@click="responseOptionAction(option, item)"
									:key="i"
									v-for="(option, i) in item.responseOptions"
									size="default"
									type="primary"
									plain
								>
									{{ option.text }}
								</el-button>
							</div>
						</div>
						<div v-if="item.feedback" class="feedback">
							<el-divider class="customDivider" />
							<span>Was this response helpful?</span>
							<div class="feedbackOptionsWrapper">
								<div :key="i" v-for="(option, i) in item.feedback.options" class="fade-in">
									<el-button
										v-if="readOnly ? (option.actioned ? true : false) : true"
										:disabled="readOnly ? true : option.actioned"
										:loading="
											loadingFeedback ===
											item.feedback.inferenceResponseChatId + '_' + option.text
												? true
												: false
										"
										@click="sendFeedback(option, item.feedback)"
										class="feedbackOption"
										size="small"
										:type="option.text === 'Yes' ? 'success' : 'danger'"
										plain
									>
										{{ option.text }}
									</el-button>
								</div>
							</div>
						</div>
						<span>{{ timeDifference(item.time) }}</span>
					</div>
				</div>
			</template>
		</LazyList>
		<el-alert
			v-if="chatHistory.length > 100 && !readOnly"
			:closable="false"
			title="Performance Warning"
			description="Your test conversation exceeds 100 messages. Consider resetting your conversation."
			type="warning"
			class="performanceWarning"
			show-icon
		/>
		<div v-if="!readOnly" class="inputWrapper">
			<input
				:disabled="disableInput || surveyIsComplete"
				ref="textInput"
				@keyup.enter="processInput()"
				v-model="inputValue"
				:placeholder="
					!surveyIsComplete
						? disableInput
							? mockInferenceInProgress
								? 'Wait...'
								: 'Select a response option...'
							: 'Your message'
						: 'Survey complete'
				"
				class="queryInput"
				type="text"
			/>
			<el-divider v-if="!disableInput" style="height: 30px" direction="vertical" />
			<el-icon v-if="!disableInput" @click="processInput()" class="send"><Promotion /></el-icon>
		</div>
	</div>
</template>

<script>
import bankLogo from "../assets/bankLogo.png";
import tovieLogo from "../assets/tovielogo.svg";
import axios from "axios";
import Markdown from "vue3-markdown-it";
import { Promotion } from "@element-plus/icons-vue";
import { v4 } from "uuid";
import thinkingAnimation from "./thinkingAnimation.vue";
import { timeDifference } from "../utils/time.js";
import widgetWhite from "../assets/widgetWhite.svg";
import LazyList from "lazy-load-list/vue";
import { NodeHtmlMarkdown } from "node-html-markdown";
const nhm = new NodeHtmlMarkdown();
export default {
	components: {
		Promotion,
		Markdown,
		thinkingAnimation,
		LazyList,
	},
	async beforeMount() {
		this.loadingTranscript = false;
	},
	data() {
		return {
			bankLogo: bankLogo,
			tovieLogo: tovieLogo,
			widgetIcon: widgetWhite,
			loadingTranscript: true,
			loadingFeedback: "",
			inputValue: "",
			chatHistory: [],
			inferenceInProgress: false,
			loading: false,
			agentId: null,
			readOnlySession: null,
			showResetSessionWarning: false,
			readOnly: false,
			sessionId: null,
			disableInput: true,
			mockInferenceInProgress: true,
			surveyIsComplete: false,
			department: "Unspecified",
		};
	},
	async mounted() {
		this.department = this.$route.params.version; //requested by client
		this.sessionId = v4();
		await this.processResponse("/start");
	},
	methods: {
		timeDifference(date) {
			return timeDifference(new Date(), new Date(date));
		},
		processInput(specific) {
			if (!this.inputValue && !specific) {
				return;
			}
			this.inferenceInProgress = true;
			const value = this.inputValue || specific;
			this.inputValue = "";
			this.chatHistory.push({ text: value, actor: "user", time: new Date() });
			this.$nextTick(() => {
				document.getElementsByClassName("consoleBody")[0].scrollTop =
					document.getElementsByClassName("consoleBody")[0].scrollHeight;
			});
			setTimeout(async () => {
				await this.processResponse(value);
			}, 500);
		},
		async processResponse(userInput) {
			const chatId = v4();
			this.chatHistory.push({ text: "", actor: "agent", chatId: chatId, time: new Date(), feedback: null });
			this.$nextTick(() => {
				document.getElementsByClassName("consoleBody")[0].scrollTop =
					document.getElementsByClassName("consoleBody")[0].scrollHeight;
			});
			setTimeout(async () => {
				const result = await axios.post(
					`https://platform.tovie.ai/chatadapter/chatapi/ermrYoOV:3ed6ceaba08ad1f20247574dbe4ab3a0afc96944`,
					{
						clientId: this.sessionId,
						query: userInput,
						data: {
							customData: {
								org: this.department,
							},
						},
					}
				);
				if (result.data?.data?.replies) {
					const lastReply = result.data?.data?.replies[result.data?.data?.replies.length - 1];
					if (lastReply.type === "buttons") {
						this.disableInput = true;
					} else {
						this.disableInput = false;
						this.$refs.textInput.focus();
					}
					let messageChunks = [];
					const chat = this.chatHistory.find((each) => {
						return each.chatId === chatId;
					});
					let containsVideoLink = false;
					messageChunks = result.data?.data?.replies
						.map((each) => {
							if (each.text === "<END>") {
								this.surveyComplete();
								return "";
							} else if (each.text?.includes("<VIDEO>")) {
								containsVideoLink = true;
								return `[![IMAGE ALT TEXT HERE](https://img.youtube.com/vi/${
									each.text.split("<VIDEO>")[1]
								}/0.jpg)](https://www.youtube.com/watch?v=${each.text.split("<VIDEO>")[1]})`;
							} else if (each.text?.includes("<TOPAGE>")) {
								this.$emit("toPage", each.text.split("<TOPAGE>")[1]);
							} else {
								return each.html ? nhm.translate(each.html) : each.text;
							}
						})
						.join("\n\n")
						.split("");
					if (containsVideoLink) {
						messageChunks = [messageChunks.join("")];
					}
					this.mockInferenceInProgress = true;
					messageChunks.forEach((text, i) => {
						setTimeout(() => {
							chat.text = chat.text + text;
							this.$nextTick(() => {
								document.getElementsByClassName("consoleBody")[0].scrollTop =
									document.getElementsByClassName("consoleBody")[0].scrollHeight;
							});
							if (i === messageChunks.length - 1) {
								if (lastReply.type === "buttons") {
									chat.responseOptions = lastReply.buttons;
								}
								this.mockInferenceInProgress = false;
								this.inferenceInProgress = false;
								this.$refs.textInput.focus();
								//account for animation delay auto scroll down again
								this.$nextTick(() => {
									document.getElementsByClassName("consoleBody")[0].scrollTop =
										document.getElementsByClassName("consoleBody")[0].scrollHeight;
								});
							}
						}, i * 0);
					});
					if (containsVideoLink) {
						setTimeout(() => {
							document.getElementsByClassName("consoleBody")[0].scrollTop =
								document.getElementsByClassName("consoleBody")[0].scrollHeight;
						}, 100);
					}
				}
			}, 1000);
		},
		surveyComplete() {
			this.surveyIsComplete = true;
			this.$emit("surveyComplete");
		},
		async resetConversation() {
			this.mockInferenceInProgress = true;
			this.disableInput = true;
			this.sessionId = v4();
			this.chatHistory = [];
			await this.processResponse("/start");
		},
		async responseOptionAction(option, item) {
			item.responseOptionActioned = true;
			await this.processInput(option.text);
		},
	},
};
</script>

<style scoped>
.header {
	height: 50px;
	width: 100%;
	background-color: var(--el-color-primary);
	display: inline-flex;
	align-items: center;
}

.chatConsole {
	height: 100%;
	background-color: white;
	position: relative;
	overflow: hidden;
}

.consoleBody {
	padding: 20px;
	overflow-y: scroll !important;
	height: calc(100% - 84px) !important;
	padding-bottom: 150px;
}

.chatItem {
	display: flex;
	flex-direction: row;
	align-items: top;
	margin-bottom: 10px;
}

.user {
	flex-direction: row-reverse;
}

.user .avatarWrapper {
	margin-left: 10px;
}

.chatItem p {
	margin-top: 0px;
	font-size: 16px;
}

.content {
	margin-left: 10px;
	background-color: #f4f8fe;
	padding: 10px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-right-radius: 8px;
	max-width: 90%;
	word-wrap: break-word;
}

.content :deep(code) {
	border-radius: 4px !important;
	font-size: 12px;
}

.content span {
	font-size: 12px;
	color: #4b535b;
}

.user .content {
	margin-left: auto;
	background-color: #fef4f8;
}

.inputWrapper {
	height: 60px;
	width: 90%;
	background: white;
	margin: auto;
	box-shadow: 0px 14px 28px 0px #0c2b421f;
	border-radius: 4px;
	border: 2px solid #e0e1e3;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	bottom: 20px;
	margin: auto;
	left: 0;
	right: 0;
}

.performanceWarning {
	position: absolute;
	width: 90%;
	bottom: 100px;
	margin: auto;
	left: 0;
	right: 0;
	box-shadow: 0px 14px 28px 0px #0c2b421f;
}

.resetSessionsWarning {
	position: absolute;
	width: 90%;
	top: 100px;
	margin: auto;
	left: 0;
	right: 0;
	box-shadow: 0px 14px 28px 0px #0c2b421f;
	z-index: 10;
}

.queryInput {
	width: 90%;
	border: none;
	padding-left: 20px;
	font-size: 18px;
}

.send {
	font-size: 25px;
	margin-left: auto;
	margin-right: 20px;
	color: var(--el-color-primary);
	cursor: pointer;
	margin-left: 5px;
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.sources {
	margin-top: 10px;
}

.feedback {
	display: flex;
	flex-direction: column;
}

.sources .el-button + .el-button {
	margin-left: unset !important;
}

.source {
	margin-bottom: 10px;
	margin-right: 10px;
	margin-top: 10px;
}

.feedbackOption {
	margin-bottom: 10px;
	margin-top: 10px;
	margin-right: 10px;
}

.feedbackOptionsWrapper {
	display: flex;
}

.customDivider {
	margin: 10px 0;
}

.widgetIcon {
	height: 30px;
	margin-left: 20px;
}
</style>
